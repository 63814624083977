<template>
  <div :style="theme" class="router_box">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  created() {
    let color = JSON.parse(localStorage.getItem('bg'))
    let body = document.getElementById('body')
    let bg = '--nav-bg'
    body.style = bg + ':' + color
    this.$tool.screenZoom()
  }
}
</script>

<style lang="scss">
@import "../public/static/css/reset.css";
@import "../public/static/css/index.scss";
@import "../public/static/css/table.scss";
@import "../public/static/css/home.scss";
@import "./common/font/font.css";
.router_box{
  height: 100%;
}
@font-face {
  font-family: 'font2'; /* 字体名称 */
  src: url('../public/static/css/font/font2.otf') format('truetype'); /* 字体文件路径和格式 */
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s; /* 定义过渡效果的持续时间和类型 */
}

.fade-enter, .fade-leave-to {
  opacity: 0; /* 初始状态和结束状态 */
}

div,
span {
  font-family: PingFang,Inte;
}

.table-search {
  //padding: 1.851vh 0 10px;
}

.table-arrange-active {
  cursor: pointer;
  color: rgb(23, 144, 255);
}

.el-input__inner:focus {
  border-color: #B6BFCF !important;
}

.el-message-box{
  .el-button--primary{
    background: #4D648D;
  }
  .el-button--primary:hover{
    background: #4D648D;
  }
}

</style>
