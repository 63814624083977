export default {
  /**
   * Save data
   * 
   * @param {*} key 
   * @param {*} value 
   */
  set(key, value) {
    window.localStorage.setItem(key, JSON.stringify(value))
  },

  /**
   * get data
   * 
   * @param {*} key 
   */
  get(key) {
    if (!window.localStorage.getItem(key)) {
      return '';
    }
    return JSON.parse(window.localStorage.getItem(key))
  },

  /**
   * Delete data
   * 
   * @param {*} key 
   */
  remove(key) {
    window.localStorage.removeItem(key)
  }
}