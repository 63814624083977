import router from '@/router';
import dayjs from "dayjs";
import store from "@/store";
import i18n from "@/i18n"
export default {
    dateFormat(date, fmt) {
        console.log(date, fmt)
        var obj = {
            "y+": date.getFullYear,
            "M+": date.getMonth() + 1,
            "d+": date.getDate(),
            "h+": date.getHours(),
            "m+": date.getMinutes(),
            "s+": date.getSeconds(),
            "q+": Math.floor((date.getMonth() + 3) / 3),
            "S": date.getMilliseconds()
        };
        if (/(y+)/.test(fmt))
            fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
        for (var k in obj)
            if (new RegExp("(" + k + ")").test(fmt))
                fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (obj[k]) : (("00" + obj[k]).substr(("" + obj[k]).length)));
        return fmt;
    },
    getCurrentDate() {
        var now = new Date();
        var year = now.getFullYear();
        var month = now.getMonth();
        var date = now.getDate();
        var day = now.getDay();
        var hour = now.getHours();
        var minu = now.getMinutes();
        var sec = now.getSeconds();
        month = month + 1;
        if (month < 10) month = "0" + month;
        if (date < 10) date = "0" + date;
        if (hour < 10) hour = "0" + hour;
        if (minu < 10) minu = "0" + minu;
        if (sec < 10) sec = "0" + sec;
        let time = year + "-" + month + "-" + date + " " + hour + ":" + minu + ":" + sec;
        return time;
    },
    filterParams(obj) {
        var newPar = {};
        for (var key in obj) {
            if (
                (obj[key] === 0 || obj[key] === false || obj[key]) &&
                obj[key].toString().replace(/(^\s*)|(\s*$)/g, "") !== ""
            ) {
                newPar[key] = obj[key];
            }
        }
        return newPar;
    },
    toRolePage() {
        store.commit('setInitRegisteredLogin', false);
        router.replace({path: store.state.redirectPath}).then(()=>{
            router.go(0)
        }).catch(()=>router.go(0))
    },
    CheckEmail(email) {
        var reg = /^\w+((.\w+)|(-\w+)|(\n\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/;
        if (email === "") {
            return false;
        } else if (!reg.test(email)) {
            return false;
        } else {
            return true;
        }
    },
    isPhone(phone) {
        var reg = /^1[1|2|3|4|5|6|7|8|9][0-9]\d{8}$/;
        if (phone === "") {
            return false;
        } else if (!reg.test(phone)) {
            return false;
        } else {
            return true;
        }
    },
    adjustElementPosition(arr, oldIndex, newIndex) {//调整数组顺序
        if (newIndex >= arr.length) {
            newIndex = arr.length - 1;
        }
        const element = arr.splice(oldIndex, 1)[0];
        arr.splice(newIndex, 0, element);
        return arr;
    },
    isExpire(date, day = 30) { //判断是否在到期时间内
        const targetDate = dayjs(date); // 替换成您的目标日期
// 获取当前日期
        const currentDate = dayjs();
// 计算目标日期与当前日期之间的天数差
        const daysDifference = targetDate.diff(currentDate, 'day');
        if (daysDifference < day) return true
    },
    handleTree(data, id, parentId, children, rootId) {
        id = id || 'id'
        parentId = parentId || 'parentId'
        children = children || 'children'
        rootId = rootId || Math.min.apply(Math, data.map(item => {
            return item[parentId]
        })) || 0
        //对源数据深度克隆
        const cloneData = JSON.parse(JSON.stringify(data))
        //循环所有项
        const treeData = cloneData.filter(father => {
            let branchArr = cloneData.filter(child => {
                //返回每一项的子级数组
                return father[id] === child[parentId]
            });
            branchArr.length > 0 ? father.children = branchArr : '';
            //返回第一层
            return father[parentId] === rootId;
        });
        return treeData !== '' ? treeData : data;
    },
    nowSize(val, initWidth = 1920) {
        const nowClientWidth = document.documentElement.clientWidth;
        return val * (nowClientWidth / initWidth);
    },

    getChartMaxMinValue(type, value, mark) {
        function formatDecimal(num) {
            // 判断是否为数字
            if (typeof num !== 'number' || isNaN(num)) {
                return num;
            }

            // 判断是否为小数
            if (Number.isInteger(num)) {
                return num; // 如果是整数，则直接返回
            } else {
                // 如果是小数，则保留两位小数
                return Number(num.toFixed(2));
            }
        }

        let retVal
        if (type === 1) {
            if ((mark || mark === 0) && (value < mark)) retVal = mark + Math.abs(mark * 0.1);
            else retVal = value + Math.abs(value * 0.1);
        } else {
            if ((mark || mark === 0) && (value > mark)) retVal = mark - Math.abs(mark * 0.1);
            else retVal = value - Math.abs(value * 0.1);
        }
        return formatDecimal(retVal)
    },
    colorToRgba(color, alpha) {
        // 解析颜色的 RGB 分量
        const match = color.match(/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i);

        if (!match) {
            throw new Error('Invalid color format');
        }

        // 将十六进制的颜色分量转换为十进制
        const [, red, green, blue] = match.map(component => parseInt(component, 16));

        // 返回带有透明度的 rgba 表示
        return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
    },
    countSize(value) {
        const devicePixelRatio = window.devicePixelRatio
        let windowWidth = getWindowWidth(); // 获取当前浏览器窗口宽度
        if (windowWidth < 1440) windowWidth = 1440
        const scaleFactor = windowWidth / 1920; // 假设设计稿宽度为1920px，计算缩放比例
        return value * scaleFactor; // 根据缩放比例计算值
        function getWindowWidth() {
            return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        }
    },
    setEChartsNoData(show) {
        return {
            show,
            text: i18n.t('No_Data'),
            x: 'center',
            y: 'center',
            textStyle: {
                fontSize: 14,
                fontWeight: 'normal',
                color: '#34335b'
            }
        }
    },
    screenZoom(operation = true) {
        const scaleSelected = {
            1.25: 0.8,
            1.50: 0.65,
            1: 1
        }
        const scale = operation ? scaleSelected[window.devicePixelRatio]  : 1
        bodyScale();

        function bodyScale() {
            document.body.style.zoom = scale;
            //解决zoom放大缩小导致echarts位置偏移问题
            let strScale = `scale(${scale})`
            var obj = {
                zoom: 1 / scale,
                transform: strScale,
                transformOrigin: "0 0",

            }
            insertCss("canvas", obj);
        }

        function insertCss(select, styles) {
            if (document.styleSheets.length === 0) { //如果没有style标签,则创建一个style标签
                var style = document.createElement("style");
                document.head.appendChild(style);
            }
            var styleSheet = document.styleSheets[document.styleSheets.length - 1]; //如果有style 标签.则插入到最后一个style标签中
            var str = select + " {"; //插入的内容必须是字符串,所以得把obj转化为字符串
            for (var prop in styles) {
                str += prop.replace(/([A-Z])/g, function (item) { //使用正则把大写字母替换成 '-小写字母'
                    return "-" + item.toLowerCase();
                }) + ":" + styles[prop] + ";"
            }
            str += "}";
            styleSheet.insertRule(str, styleSheet.cssRules.length); //插入样式到最后一个style标签中的最后面
        }
    }
}


