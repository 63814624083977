import Vue from "vue";
import VueI18n from 'vue-i18n'
import zh from "./lang/zh"
import en from "./lang/en"
import elementEnLocale from 'element-ui/lib/locale/lang/en' // element-ui 英文包
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'// element-ui 中文包

Vue.use(VueI18n);   // 全局注册国际化包

var localLang = localStorage.getItem('language')
var language = navigator.language === 'zh-CN' ? 'zh_CN' : 'en'
if (!localLang) {
  localStorage.setItem('language', language)
}

const messages = {
  en: {
    ...en,
    ...elementEnLocale
  },
  zh_CN: {
    ...zh,
    ...elementZhLocale
  }
}
// 准备翻译的语言环境信息
const i18n = new VueI18n({
  locale: localLang || language,   // 初始化中文
  messages
});

export default i18n
