import Vue from 'vue'
import Router from 'vue-router'
import i18n from '../i18n'
import storage from "../fetch/storage";
import store from '../store';
import api from '../fetch/api'

Vue.use(Router)
const infoMsg = storage.get('infoMsg')
let activeMenu;
if (infoMsg){
    store.commit('setTenantId', infoMsg.tenantId)
    store.commit('setTenantKey', infoMsg.tenantKey)
}
const router = new Router({
    routes: [
        {
            path: '/login',
            name: 'login',
            component: () => import('@/views/login'),
            meta: {
                title: 'router.login'
            }
        },
        {
            path: '/lt/login',
            name: 'login',
            component: () => import('@/views/login/lt/login'),
            meta: {
                title: 'router.login'
            }
        },
        {
            path: '/btsz/login',
            name: 'login',
            component: () => import('@/views/login/btsz/login'),
            meta: {
                title: 'router.login'
            }
        },
        {
            path: '/yssz/login',
            name: 'login',
            component: () => import('@/views/login/yssz/login'),
            meta: {
                title: 'router.login'
            }
        },
        {
            path: '/phm/login',
            name: 'login',
            component: () => import('@/views/login/yssz/login'),
            meta: {
                title: 'router.login'
            }
        },
    ]
})
const indexRouter = [
    {
        path: '/alarmsDetails',
        name: '/alarmsDetails',
        component: () => import('@/views/alarms/alarmDetails.vue'),
        meta: {
            hidden: true,
        }
    },
    {
        path: '/pAlarmsDetails',
        name: '/pAlarmsDetails',
        component: () => import('@/views/pAlarms/alarmDetails.vue'),
        meta: {
            hidden: true,
            title: '报警详情'
        }
    },
    {
        path: '/devicesDetails',
        name: '/devicesDetails',
        component: () => import('@/views/devices/devicesDetails.vue'),
        meta: {
            hidden: true,
            title: '设备详情'
        }
    },
    {
        path: '/iotDetails',
        name: '/iotDetails',
        component: () => import('@/views/devices/devicesDetails.vue'),
        meta: {
            hidden: true,
            title: 'IOT详情'
        }
    },
    //设备详情
    {
        path: '/devicesNewDetails',
        name: '/devicesNewDetails',
        component: () => import('@/views/devicesNew/devicesDetails.vue'),
        meta: {
            hidden: true,
            title: '设备详情'
        }
    },
    //设备管理详情
    {
        path: '/devicesNewEditDetails',
        name: '/devicesNewEditDetails',
        component: () => import('@/views/sets/deviceNew/devicesDetails.vue'),
        meta: {
            hidden: true,
            title: '设备详情'
        }
    },
    //传感器详情
    {
        path: '/sensorInfo',
        name: '/sensorInfo',
        component: () => import('@/views/devicesNew/sensorInfo.vue'),
        meta: {
            hidden: true,
            title: '传感器详情'
        }
    },
    {
        path: '/devicesHistory',
        name: '/devicesHistory',
        component: () => import("@/views/devices/downloadHistory.vue"),
        meta: {
            hidden: true,
            title: '下载历史'
        }
    },
    {
        path: '/pEditDevice',
        name: '/pEditDevice',
        component: () => import("@/views/sets/iot/editDevice.vue"),
        meta: {
            hidden: true,
            title: '编辑IOT'
        }
    },
    {
        path: '/editDevices',
        name: '/editDevices',
        component: () => import("@/views/sets/device/editDevice.vue"),
        meta: {
            hidden: true,
        }
    },
    //维修记录
    {
        path: '/operation',
        name: 'operation',
        component: () => import('@/views/devicesNew/operation'),
        meta: {
            hidden: true,
            title: '运维详情'
        }
    },
]

function setupRoutes() {
    api.getMenus({versionCodeNum: process.env.VUE_APP_CODE}).then(res => {
        let routeList = [
            {
                "name" : "/lDashboard",
                "path" : "/lDashboard",
                "hidden" : false,
                "component" : "lDashboard/index",
                "meta" : {
                    "title" : "仪表盘",
                    "icon" : "dashboard",
                    "noCache" : false,
                    "hidden" : false,
                    "menuKey" : "",
                    "languageKey" : "Dashboard"
                }
            },
            {
                "name" : "/personal",
                "path" : "/personal",
                "hidden" : false,
                "component" : "personal/index",
                "meta" : {
                    "title" : "个人中心",
                    "icon" : "user",
                    "noCache" : false,
                    "hidden" : false,
                    "menuKey" : "",
                    "languageKey" : "User Center"
                }
            }
        ]

        if (res.data && res.data.length > 0) {
            routeList = res.data;
        }
        let menus = routeList.map(item => {
            let rou = {
                ...item,
                component: () => import(`@/views/${item.component}.vue`),
            }
            if (rou.path === '/personal') {
                rou.children = [
                    {
                        path: 'info/:id',
                        component: () => import("@/views/personal/info"),
                        meta: {
                            parentName: rou.name
                        }
                    },
                    {
                        path: 'account/:id',
                        component: () => import("@/views/personal/account"),
                        meta: {
                            parentName: rou.name
                        }
                    },
                    {
                        path: 'setPassword/:id',
                        component: () => import("@/views/personal/setPassword"),
                        meta: {
                            parentName: rou.name
                        }
                    },
                    {
                        path: 'deviceGroup/:id',
                        component: () => import("@/views/personal/deviceGroup"),
                        meta: {
                            parentName: rou.name
                        }
                    },
                ]
                rou.meta.hideChildren = true
                rou.redirect = '/personal/info/1'
            }
            if (rou.children && !rou.meta.hideChildren) {
                rou.children = rou.children.map(v => {
                    return {
                        ...v,
                        component: () => import(`@/views/${v.component}.vue`)
                    }
                })
            }
            return rou
        })
        let url = 'index'
        if (store.state.isLt) url = 'lIndex'
        menus = [...menus, ...indexRouter]
        if (menus.length) {
            store.commit('setStateValue', {key: 'redirectPath', value: menus[0].path})
        }
        const routers = [{
            path: '/',
            redirect: store.state.redirectPath,
            component: () => import(`@/views/${url}.vue`),
            children: [...menus]
        }]
        store.state.menus = menus
        store.state.permissions = res.permissions || []
        router.addRoutes(routers)
    })
}

router.beforeEach((to, from, next) => {
    const query = to.query
    if (query.token) storage.set('token', query.token)
    if(query.tenantId) store.commit('setTenantId',query.tenantId)
    const token = storage.get('token')
    if (!token && to.name !== 'login') {
        return next('/login');
    }
    if (token && !store.state.initRegisteredLogin && to.name !== 'login') {
        store.commit('setInitRegisteredLogin', true);
        setupRoutes()
    }
    const name = to.meta.parentName || to.name
    if (!to.meta.hidden && name) {
        activeMenu = name
        store.commit('updateActiveMenu', activeMenu);
        storage.set('activeMenu', activeMenu)
    }
    next();
});

router.afterEach((to, from) => {
    if (to.name === 'login' && from.name) {
        router.go(0)
    }
    let title = to.meta.title || (to.matched.length && to.matched[to.matched.length - 2].meta.title) || 'RIIAlert';
    window.document.title = i18n.t(title) || title
});
export default router
