const math = require('mathjs');

export function getMathResult(expression, scopeValue) {
    if(expression){
        let expressionDecode = htmlDecode(expression);
        // console.log("[getMathResult] ---> ", expressionDecode, scopeValue);
        try {
            const scope = {x: scopeValue};
            const result = math.evaluate(expressionDecode, scope);
            // console.log("[getMathResult] evaluate ---> ", result);
            return result.toFixed(2);
        } catch (e) {
            // console.log("[getMathResult] --> ", e);
        }
    }
    return scopeValue;
}


function htmlDecode(str) {
    return str.replace(/&amp;/g, "&").replace(/&lt;/g, "<").replace(/&gt;/g, ">");
}
